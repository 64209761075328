import { useState } from 'react';
import axios from 'axios';
import { getCookieKey } from '../../../LocalStorage/CookiesStorage';
import environment from '../../../Environments/Environment';
import { getFileType } from '../../../SceneComponents/SceneComponents';

const useUploadModel = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);

    const uploadModel = async (formData, category, file) => {
        if (getFileType(file) !== CATEGORY_CHECK[category].file_type) {
            setError({ message: 'Please select the correct file type only.' });
            return;
        }

        // Validate file size
        if (file.size / (1024 ** 2) > CATEGORY_CHECK[category].size) {
            setError({ message: `Please select a file less than ${category}MB.` });
            return;
        }
        setLoading(true);
        setError(null);
        setResponse(null);

        try {
            const token = 'Token ' + getCookieKey('token');
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: token,
                },
            };

            const url = `${environment.server_root}/api/avatar/upload_models/`;
            const res = await axios.post(url, formData, config);

            const data = await res.data;
            data.status_code = res.status;
            setResponse(data);
            return data;
        } catch (err) {
            setError(err);
            console.error('Error uploading model:', err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return { uploadModel, loading, error, response };
};

export default useUploadModel;

const CATEGORY_CHECK = {
    '1': { file_type: 'model', size: 60 },
    '5': { file_type: 'image', size: 10 },
    '4': { file_type: 'video', size: 15 },
}