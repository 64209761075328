import React, { useEffect, useState } from 'react'
import { get_profile_info_profile_id, get_profile_stats, get_user_client_challenges } from '../../../../Servicies/ProfileService'
import { color_2, color_3 } from '../../../../Styles/Colors'
import { useParams } from 'react-router-dom'
import ThreeDotsLoader from '../../../LoaderPages/ThreeDotsLoader'
import { dark_blue_2 } from '../../../../Styles/Colors'

const PointsTable = ({ statsData }) => {
    const { profile_id } = useParams()
    const [stats, setStats] = useState(statsData || null)
    const [collection,setCollection] = useState([])
    const [loader, setLoader] = useState(false)
    useEffect(() => {
      get_challeneges()
    }, [])

    const get_challeneges = () => {
      get_user_client_challenges().then(result => {
        if (result.success) {
            setCollection(result.data)
        }
    })
    }

    return (
        <div>
      {loader ?
        <div className='w3-center'>
          <ThreeDotsLoader center={true} size={30} />
        </div>
        :
        <>
          <div className='w3-row'>
            <div className='w3-row w3-padding-small '  style={{maxHeight:'78vh',overflow:'auto'}}>
            <h2 className='w3-center no-margin w3-padding black-card' style={{ borderRadius: '8px 8px 0px 0px' }}>Challenges</h2>
              <table id='data-table' className='w3-table-all w3-hoverable dashboard_table'>
                <thead>
                  <tr style={{ backgroundColor: "var(--dark-blue-2)", color: "white" }}>
                    <th className='w3-padding w3-center'>S.No</th>
                    <th className='w3-padding w3-center w3-hide-small'>Challenge</th>
                    <th className='w3-padding w3-center'>Challenge Points</th>
                    <th className='w3-padding w3-center'>Points Gained</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {
                      collection.length !== 0 &&
                      collection.map((item, index) => (
                        <tr key={index} style={{ cursor: "pointer" }}>
                          <td className='w3-center'>
                            {index+1}
                          </td>
                          <td className='w3-center w3-hide-small'>
                            {item.description}
                          </td>
                          <td className='w3-center'>
                            {item.challenge_points}
                          </td>
                          <td className='w3-center'>
                            {item.points_scored}
                          </td>
                        </tr>
                      ))
                    }
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </>
      }
    </div>
    )
}

export default PointsTable
