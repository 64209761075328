import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import gsap from 'gsap';
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

const Admin = () => {
  const mountRef = useRef(null);
  const hoveredButtonRef = useRef(null); // Ref for hoveredButton
  const selectedIndexRef = useRef(null); // Ref for hoveredButton
  const buttonData = useRef(null); // Ref for hoveredButton
  const navigate = useNavigate()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [hoveredButton, setHoveredButton] = useState(null);
  const [shapes, setShapes] = useState([])

  const pentagonPositions = [
    { x: 0, y: 6, z: 0 },  // Move the first object higher up
    { x: -6, y: 2, z: 0 }, // Spread out to the left and slightly upward
    { x: 6, y: 2, z: 0 },  // Spread out to the right and slightly upward
    { x: -4, y: -3, z: 0 }, // Move further left and down
    { x: 4, y: -3, z: 0 },  // Move further right and down
  ];

  useEffect(() => {
    selectedIndexRef.current = 0
    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      mountRef.current.clientWidth / mountRef.current.clientHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    renderer.shadowMap.enabled = true;
    mountRef.current.appendChild(renderer.domElement);

    // Camera position
    camera.position.set(0, 2, 12);

    // Background color
    scene.background = new THREE.Color(0x161C23);

    // Ground plane for shadows
    const groundGeometry = new THREE.PlaneGeometry(50, 50);
    const groundMaterial = new THREE.ShadowMaterial({ opacity: 0.2 });
    const ground = new THREE.Mesh(groundGeometry, groundMaterial);
    ground.rotation.x = -Math.PI / 2;
    ground.position.y = -5;
    ground.receiveShadow = true;
    scene.add(ground);

    // Ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    // Spotlight for dramatic lighting
    const spotlight = new THREE.DirectionalLight(0xffffff, 2);
    spotlight.position.set(10, 20, 10);
    spotlight.castShadow = true;
    scene.add(spotlight);

    // Cursor light following the mouse
    const cursorLight = new THREE.PointLight(0xffa500, 1, 10);
    cursorLight.castShadow = true;
    scene.add(cursorLight);

    // Create buttons
    const createButton = (geometry, color, position, url, label) => {
      const material = new THREE.MeshStandardMaterial({ color });
      const button = new THREE.Mesh(geometry, material);
      button.position.set(position.x, position.y, position.z);
      button.castShadow = true;
      button.userData = { url, originalScale: button.scale.clone(), originalRotation: button.rotation.clone(), label };

      const pointLight = new THREE.PointLight(color, 1.5, 5); // Adjust intensity and distance
      pointLight.position.set(position.x, position.y + 2, position.z); // Slightly above the object
      scene.add(pointLight);

      return button;
    };



    const createStars = (count, radius) => {
      const geometry = new THREE.BufferGeometry();
      const vertices = [];

      for (let i = 0; i < count; i++) {
        const x = (Math.random() - 0.5) * radius * 2;
        const y = (Math.random() - 0.5) * radius * 2;
        const z = (Math.random() - 0.5) * radius * 2;
        vertices.push(x, y, z);
      }

      geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

      const material = new THREE.PointsMaterial({
        color: 0xffffff,
        size: 0.1,
        transparent: true,
        opacity: 0.8,
      });

      return new THREE.Points(geometry, material);
    };

    // Add stars to the scene
    const starCount = 500; // Number of stars
    const starRadius = 50; // Radius of the star field
    const stars = createStars(starCount, starRadius);
    scene.add(stars);

    // Animate stars
    const animateStars = () => {
      stars.rotation.x += 0.0005; // Slowly rotate the stars
      stars.rotation.y += 0.0005;
    };

    const buttons = [
      createButton(new THREE.TorusKnotGeometry(1, 0.4, 100, 16), 0xff6347, pentagonPositions[0], '/profile', 'Training Home'),
      createButton(new THREE.OctahedronGeometry(1.2), 0x1e90ff, pentagonPositions[1], '/profile/dashboard', 'Dashboard'),
      createButton(new THREE.CylinderGeometry(1, 1, 2, 32), 0x32cd32, pentagonPositions[2], '/profile/admin', 'Admin'),
      createButton(new THREE.BoxGeometry(2, 2, 2), 0xffd700, pentagonPositions[3], '/create-vrwebsite', 'Create Website'),
      createButton(new THREE.DodecahedronGeometry(1.2), 0x8a2be2, pentagonPositions[4], '/create', 'Create VR Experience'),
    ];

    buttons.forEach((button) => scene.add(button));
    setShapes(buttons)
    // Raycaster for hover and click detection
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    // Mouse move handler
    const onMouseMove = (event) => {
      const rect = mountRef.current.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      const width = rect.width;
      const height = rect.height;
      const x = ((event.clientX - rect.left) / width) * 2 - 1;
      const y = -((event.clientY - rect.top) / height) * 2 + 1;

      const mouseVector = new THREE.Vector3(x, y, -1).unproject(camera);
      const direction = mouseVector.sub(camera.position).normalize();
      const distance = -camera.position.z / direction.z;
      const position = camera.position.clone().add(direction.multiplyScalar(distance));
      position.z += 1;

      cursorLight.position.copy(position);
    };


    // Mouse click handler
    const onClick = () => {
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(buttons);
      if (intersects.length > 0) {
        const { url } = intersects[0].object.userData;
        if (url) {
          navigate(url)
        }
      }
    };

    // Animation loop
    const animate = () => {
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(buttons);
      if (intersects.length > 0) {
        renderer.domElement.style.cursor = 'pointer';
        const button = intersects[0].object;
        if (selectedIndexRef.current !== null) {
          var buttonActive = buttons[selectedIndexRef.current]
          gsap.to(buttonActive.scale, { duration: 0.3, x: 1, y: 1, z: 1 });
          gsap.to(buttonActive.rotation, { duration: 0.3, y: 0, z: 0 });
          selectedIndexRef.current = null
        }
        buttonData.current = button.userData
        setHoveredButton(button.userData);

        // Handle hover logic
        gsap.to(button.scale, { duration: 0.1, x: 1.2, y: 1.2, z: 1.2 });
        gsap.to(button.rotation, { duration: 0.1, y: button.rotation.y + 0.05, z: button.rotation.z + 0.05 });
        hoveredButtonRef.current = button
      } else {
        // Reset hover state when no button is hovered
        if (selectedIndexRef.current !== null) {
          var button = buttons[selectedIndexRef.current]
          buttonData.current = button.userData
          setHoveredButton(button.userData);
          gsap.to(button.scale, { duration: 0.1, x: 1.2, y: 1.2, z: 1.2 });
          gsap.to(button.rotation, { duration: 0.1, y: button.rotation.y + 0.05, z: button.rotation.z + 0.05 });
        }
        if (hoveredButtonRef.current) {
          gsap.to(hoveredButtonRef.current.scale, { duration: 0.3, x: 1, y: 1, z: 1 });
          gsap.to(hoveredButtonRef.current.rotation, { duration: 0.3, y: 0, z: 0 });
          hoveredButtonRef.current = null
        }

        renderer.domElement.style.cursor = 'default';
      }

      animateStars(); // Move stars
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };


    // Event listeners
    renderer.domElement.addEventListener('mousemove', onMouseMove);
    renderer.domElement.addEventListener('click', onClick);

    // Start animation loop
    animate();

    // Cleanup
    return () => {
      renderer.domElement.removeEventListener('mousemove', onMouseMove);
      renderer.domElement.removeEventListener('click', onClick);
      scene.traverse((object) => {
        if (object.geometry) object.geometry.dispose();
        if (object.material) object.material.dispose();
      });
      renderer.dispose();
    };
  }, []);

  const changeActiveModel = (action) => {
    var newIndex = selectedIndex
    var button = shapes[newIndex]
    gsap.to(button.scale, { duration: 0.3, x: 1, y: 1, z: 1 });
    gsap.to(button.rotation, { duration: 0.3, y: 0, z: 0 });
    if (action === "add") {
      if (newIndex < pentagonPositions.length - 1) {
        newIndex += 1
      }
    } else {
      if (newIndex > 0) {
        newIndex -= 1
      }
    }
    setSelectedIndex(newIndex)
    selectedIndexRef.current = newIndex
  }
  console.log(buttonData.current)

  return (
    <div className='w3-display-container'>
      <div ref={mountRef} style={{ width: '100%', height: '100vh' }} />
      <div className='w3-padding w3-display-topmiddle'>
        <div className='space-item'>
          <button onClick={() => changeActiveModel('minus')} className='gret-dark-bordered-button center-item w3-padding'>
            <MdOutlineKeyboardArrowLeft className='w3-xxlarge' />
          </button>
          <Link style={{ textDecoration: 'none' }} to={hoveredButton && hoveredButton.url} className='gret-blue-button center-item w3-padding w3-col'>
            <h4 className='w3-xlarge'>{hoveredButton && hoveredButton.label}</h4>
          </Link>
          <button onClick={() => changeActiveModel('add')} className='gret-dark-bordered-button center-item w3-padding'>
            <MdOutlineKeyboardArrowRight className='w3-xxlarge' />
          </button>
        </div>
      </div>
    </div>
  )

};

export default Admin;


