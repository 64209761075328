import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import * as colors from '../../Styles/Colors';

const ThreeDotsLoader = (props) => {
  return (
    <div className=''>
      <div>
        <div className={`${props.center && "center-item"}`}>
          <ThreeDots
            height={props.size || "20"}
            width={props.size || "20"}
            radius="9"
            color={props.color || "white"}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
        <p style={{ color: props.color || "white", textAlign: 'center' }}>{props.loadingMessage}</p>
      </div>
    </div>

  )
}

export default ThreeDotsLoader
