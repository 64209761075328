import React, { lazy, useEffect, useState, Suspense } from 'react'
import importRetry from '../../importRetry';
import { useExperienceEditing, useUpdateExperience, useUpdateExperiencePreview } from '../../Servicies/CustomHook';

import { useNavigate } from 'react-router-dom';
import { FaPowerOff } from 'react-icons/fa';
import { deleteAllCookies, getCookieKey } from '../../LocalStorage/CookiesStorage';
import ThreeDotsLoader from '../LoaderPages/ThreeDotsLoader';
import './VirtualShowroom.css'

const MyUploads = lazy(() => importRetry(() => import('./Components/MyUploads')));
const Animation = lazy(() => importRetry(() => import('./Components/Animation')));
const ActiveModels = lazy(() => importRetry(() => import('./Components/ActiveModels')));
const LinkButtons = lazy(() => importRetry(() => import('./Components/LinkButtons')));

const VirtualShowroomAdmin = () => {
    const { data, loading, error, refetch } = useExperienceEditing("VRE-My_3D_Experienc-GQS1547", '/login');
    const { data: updateData, loading: updating, error: updateError, updateExperience } = useUpdateExperience();
    const { data: uploadData, loading: updatingImage, error: updateImageError, updateExperiencePreview } = useUpdateExperiencePreview();

    const [experienceInfo, setExperienceInfo] = useState(null)
    const [activeState, setActiveState] = useState(0)

    const navigate = useNavigate()
    useEffect(() => {
        if (data) { setExperienceInfo(data) }
    }, [data])

    useEffect(() => {
        if (!getCookieKey('token')) {
            // logoutUser()
            // navigate('/dna/login')
        }
    }, [])

    const logoutUser = () => {
        deleteAllCookies()
        navigate('/dna/login')
    }

    const updateExp = () => {
        const canvas = document.getElementsByTagName("canvas")[0]
        const image = canvas.toDataURL("image/png");
        const formData = new FormData();
        formData.append("unique_id", "VRE-Art_Showroom-Co-LD31323")
        formData.append("preview_url", image)
        updateExperience(experienceInfo).then(() => {
            updateExperiencePreview(formData)
        })
    }


    return (
        <div className='w3-row gray-bg' style={{ minHeight: '100vh', fontFamily: "Roboto, sans-serif" }}>

            <div className='w3-third w3-border-right' style={{ minHeight: '100vh' }}>
                <div className='w3-padding-small dark-bg-1 border-down-1 space-item'>
                    <h3 className="w3-row"><b>Virtual Showroom Admin</b></h3>
                    <button title="Logout" className="transparent-button hover-blue center-item" style={{ padding: 8 }} >
                        <FaPowerOff className='w3-large' onClick={logoutUser} />
                    </button>
                </div>
                <div className='w3-margin w3-bar-block'>
                    <div className='w3-row w3-bar-item'>
                        <button onClick={() => setActiveState(1)} className='transparent-button w3-left-align w3-col gret-dark-bordered-button w3-round-large'>
                            Upload Product 3D Models
                        </button>
                    </div>
                    <div className='w3-row w3-bar-item'>
                        <button onClick={() => setActiveState(3)} className='transparent-button w3-left-align w3-col gret-dark-bordered-button w3-round-large'>
                            Background Images or color
                        </button>
                    </div>
                    <div className='w3-row w3-bar-item'>
                        <button onClick={() => setActiveState(4)} className='transparent-button w3-left-align w3-col gret-dark-bordered-button w3-round-large'>
                            Text for whiteBoard
                        </button>
                    </div>
                    <div className='w3-row w3-bar-item'>
                        <button onClick={() => setActiveState(5)} className='transparent-button w3-left-align w3-col gret-dark-bordered-button w3-round-large'>
                            Upload Video For display
                        </button>
                    </div>
                    <div className='w3-row w3-bar-item'>
                        <button onClick={() => setActiveState(6)} className='transparent-button w3-left-align w3-col gret-dark-bordered-button w3-round-large'>
                            Welcome greeting text (Popup)
                        </button>
                    </div>
                    <div className='w3-row w3-bar-item'>
                        {updating ?
                            <ThreeDotsLoader size="30" color="black" center />
                            :
                            <button onClick={updateExp} className='gret-blue-button w3-col'>
                                Save Changes
                            </button>
                        }
                    </div>
                </div>
            </div>
            {console.log(experienceInfo)}
            <div className='w3-twothird'>
                {activeState === 0 ?
                    <img src={experienceInfo?.preview ||"https://storage.googleapis.com/grubox-store/gret/avatar/Art%20Showroom-Texture%20Repeat/VRExpVRE-Art_Showroom-Co-LD31323.png"} className='w3-col w3-animate-right' style={{ height: '100vh' }} />
                    :
                    <Suspense fallback={<>...</>}>
                        {activeState == 1 &&
                            <ActiveModels
                                onClose={() => setActiveState(0)}
                                experienceInfo={experienceInfo}
                                setExperienceInfo={setExperienceInfo}
                            />
                        }
                        {activeState == 2 &&
                            <MyUploads
                                onClose={() => setActiveState(0)}
                                experienceInfo={experienceInfo}
                                setExperienceInfo={setExperienceInfo}
                                category_id={2}
                            />
                        }
                        {activeState == 3 &&
                            <MyUploads
                                onClose={() => setActiveState(0)}
                                experienceInfo={experienceInfo}
                                setExperienceInfo={setExperienceInfo}
                                category_id={5}
                            />
                        }
                        {activeState == 4 &&
                            <MyUploads
                                onClose={() => setActiveState(0)}
                                experienceInfo={experienceInfo}
                                setExperienceInfo={setExperienceInfo}
                                category_id={6}
                            />
                        }
                        {activeState == 5 &&
                            <MyUploads
                                onClose={() => setActiveState(0)}
                                experienceInfo={experienceInfo}
                                setExperienceInfo={setExperienceInfo}
                                category_id={4}
                            />
                        }
                        {activeState == 6 &&
                            <Animation
                                onClose={() => setActiveState(0)}
                                experienceInfo={experienceInfo}
                                setExperienceInfo={setExperienceInfo}
                            />
                        }
                        {activeState == 7 &&
                            <LinkButtons
                                onClose={() => setActiveState(0)}
                                experienceInfo={experienceInfo}
                                setExperienceInfo={setExperienceInfo}
                            />
                        }
                    </Suspense>
                }
            </div>
        </div>
    )
}

export default VirtualShowroomAdmin
