import { useState, useCallback, useEffect, useRef } from 'react';
import { getCookieKey } from '../../../LocalStorage/CookiesStorage';
import environment from '../../../Environments/Environment';

const useModelVariants = (initialCategoryID, initialUpload = false) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [pagination, setPagination] = useState({
        next_page: null,
        current_page: 1,
        total_pages: null,
    });
    const [category, setCategory] = useState(initialCategoryID);
    const [upload, setUpload] = useState(initialUpload); // State to manage the upload scope
    const abortControllerRef = useRef(null);

    const fetchModels = useCallback(async (pageNum, categoryID, uploadScope) => {
        setLoading(true);
        setError(null); // Clear previous errors

        // Abort previous fetch if any
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        const controller = new AbortController();
        abortControllerRef.current = controller;
        const token = getCookieKey('token');
        if (!token) {
            setError('No token found');
            setLoading(false);
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Authorization: 'Token ' + token },
            signal: controller.signal,
        };

        // Modify URL based on the upload scope
        let url = `${environment.server_root}/api/avatar/get_models?category_id=${categoryID}&page=${pageNum}`;
        if (uploadScope) {
            url = `${environment.server_root}/api/avatar/get_models?upload=true&category_id=${categoryID}&page=${pageNum}`;
        }

        try {
            const response = await fetch(url, requestOptions);
            const result = await response.json();

            if (response.ok) {
                setData((prevData) => [...prevData, ...result.data]);
                setPagination({
                    next_page: result.next_page,
                    current_page: pageNum,
                    total_pages: result.total_pages,
                });
            } else {
                setError(result.message || 'Error fetching data'); // Store meaningful error
            }
        } catch (err) {
            setError(err.message || 'Network error'); // Handle network errors
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        setData([]);
        setPagination({ next_page: null, current_page: 1, total_pages: null });
        fetchModels(1, category, upload); // Fetch models for the first page
    }, [category, upload, fetchModels]);

    // Function to refresh data by re-fetching from page 1
    const refreshData = useCallback(() => {
        setData([]); // Clear current data
        setPagination({ next_page: null, current_page: 1, total_pages: null }); // Reset pagination
        fetchModels(1, category, upload); // Fetch data from the first page again
    }, [category, upload, fetchModels]);

    const loadMore = useCallback(() => {
        if (pagination.next_page) {
            fetchModels(pagination.next_page, category, upload);
        }
    }, [pagination, category, upload, fetchModels]);

    return { data, loading, error, loadMore, pagination, setCategory, refreshData, setUpload };
};

export default useModelVariants;