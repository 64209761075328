import { useState, useEffect, useCallback } from 'react';
import { getCookieKey } from '../../../LocalStorage/CookiesStorage';
import environment from '../../../Environments/Environment';

const useSearchModels = (debounceDelay = 300) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    next_page: null,
    current_page: 1,
    total_pages: null,
  });
  const [searchKey, setSearchKey] = useState(''); // Actual search key
  const [debouncedSearchKey, setDebouncedSearchKey] = useState(''); // Debounced value

  // Debounce the search key
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchKey(searchKey);
    }, debounceDelay);

    return () => {
      clearTimeout(handler);
    };
  }, [searchKey, debounceDelay]);

  const searchModels = useCallback(async (searchKey, page = 1) => {
    setLoading(true);
    setError(null);

    const token = 'Token ' + getCookieKey('token');
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: token },
    };

    const url = `${environment.server_root}/api/avatar/search_models/?page=${page}&search_key=${encodeURIComponent(
      searchKey
    )}`;

    try {
      const response = await fetch(url, requestOptions);
      const result = await response.json();

      if (response.ok) {
        if (result.search_result) {
          setData((prevData) => [...prevData, ...result.search_result]);
        }
        setPagination({
          next_page: result.next_page,
          current_page: page,
          total_pages: result.total_pages,
        });
      } else {
        setError(result.message || 'Error fetching search results');
      }
    } catch (err) {
      setError(err.message || 'Network error');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (debouncedSearchKey) {
      setData([]);
      setPagination({ next_page: null, current_page: 1, total_pages: null });
      searchModels(debouncedSearchKey); // Trigger search with debounced value
    }
  }, [debouncedSearchKey, searchModels]);

  const loadMore = useCallback(() => {
    if (pagination.next_page) {
      searchModels(debouncedSearchKey, pagination.next_page);
    }
  }, [pagination, debouncedSearchKey, searchModels]);

  return {
    data,
    loading,
    error,
    setSearchKey, // Allows component to set search input
    loadMore,
    pagination
  };
};

export default useSearchModels;